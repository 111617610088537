<template>
  <div>
    <!-- <ServerError v-if="ServerError" />
    <vue-element-loading :active="appLoading" spinner="bar-fade-scale" color="black" size="128" is-full-screen />-->
    <vue-element-loading
    :active="appLoading"
    spinner="bar-fade-scale"
    color="#4480C5"
    size="60"
    is-full-screen
  />

    <v-snackbar v-model="showSnackBar" color="primary" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #ffffff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #ffffff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>

    <v-layout wrap justify-center>
      <v-flex pt-5 xs11 sm11 md11 lg11 xl12>
        <!-- Header Section -->
        <v-layout wrap justify-start class="my-3">
          <v-flex
            xs12
            sm9
            md9
            lg10
            text-start
            align-center
            pt-2
            class="headingChurch"
            >Blessings & Miracles</v-flex
          >
          <v-flex xs12 sm3 md3 lg2 text-end align-center
            ><v-btn
              class="churchbtn"
              color="blue"
              style="color: white"
              @click="dialog = true"
              >ADD BLESSINGS</v-btn
            ></v-flex
          >
          <v-dialog v-model="dialog" max-width="700px">
            <v-card rounded="lg">
              <v-card-title>
                <span class="editbox">ADD BLESSINGS AND MIRACLES</span>
                <v-spacer></v-spacer>
                <v-btn color="red" icon @click="dialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>

              <v-card-text>
                <v-form>
                  <v-layout wrap justify-center px-2>
                    <v-flex xs12 pr-2>
                      <span class="label"> Miracle & Blessings: </span>
                      <v-text-field
                        class="pt-2 text-des"
                        style="font-size: 14px"
                        v-model="name"
                        outlined
                        dense
                        hide-details
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 pr-2 pt-3>
                      <span class="label"> Description </span>
                      <vue-editor
                        class="pt-2 text-des"
                        style="font-size: 14px"
                        v-model="description"
                      ></vue-editor>
                      <!-- <v-textarea class="pt-2 text-des" style="font-size:14px" v-model="description" outlined dense hide-details></v-textarea> -->
                    </v-flex>

                    <v-flex xs12 pr-2 pt-3>
                      <span class="label"> Upload Image For App Home Screen : </span>
                      <ImageComp
                        class="pt-2 text-des"
                        :singleimg="image"
                        @stepper="winStepper"
                        :componentType="'img1'"
                      />
                    </v-flex>

                    <v-flex xs12 pr-2 pt-3>
                      <span class="label"> Upload Cover Image: </span>
                      <ImageComp1
                        class="pt-2 text-des"
                        :singleImage="image"
                        @stepper="winStepper"
                        :componentType="'courseImage'"
                      />
                    </v-flex>
                  </v-layout>
                </v-form>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions class="py-4 justify-end headline grey lighten-2">
                <v-btn outlined color="grey" text @click="dialog = false"
                  ><span style="color: black">Cancel</span>
                </v-btn>
                <v-btn color="primary" @click="validateInput()"
                  >Save Changes</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>

        <v-layout wrap justify-center>
          <v-flex xs12>
            <v-data-table
              :headers="headers"
              :items="miraclelist"
              style="cursor: pointer"
              @click:row="redirectToViewPage"
              hide-default-footer
              class="elevation-1 text--black"
              id="virtual-scroll-table"
            >
              <template v-slot:[`item.description`]="{ item }">
                <span>{{ truncateDescription(item.description) }}</span>
              </template>
              <template v-slot:[`item._id`]="{ item }">
                <v-icon
                  small
                  color="primary"
                  class="ml-1"
                  @click.stop="editSlider(item)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  small
                  color="red"
                  class="ml-1"
                  @click.stop="openDeleteDialog(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center pt-2>
          <v-flex xs12>
            <div class="text-center pb-5" v-if="pages > 1">
              <v-pagination :length="pages" v-model="currentPage" color="primary white--text"
                circle></v-pagination>
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <!-- delete dialog -->
    <v-dialog v-model="deletedialog" max-width="600">
      <v-card class="dialog-card">
        <v-card-title class="grey lighten-2 d-flex justify-center">
          <v-icon color="red" size="32">mdi-alert</v-icon>
          <span class="ml-2">Confirm Deletion</span>
        </v-card-title>
        <v-card-text class="py-5 text-center text-des">
          <div class="body-1">
            Are you sure you want to delete this Miracle?
            <br />
            This action <strong>cannot be undone</strong>.
          </div>
          <v-divider class="my-3"></v-divider>
          <v-row class="pt-2" no-gutters>
            <v-col>
              <img
                src="@/assets/images/miracle2.png"
                width="70px"
                height="70px"
                class="dialog-icon"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center pa-2">
          <v-btn
            style="font-family: interBold; font-size: 13px; color: white"
            color="#cf3a45"
            @click="deletedialog = false"
            >Cancel</v-btn
          >
          <v-btn
            style="font-family: interBold; font-size: 13px; color: white"
            color="blue darken-1"
            @click="confirmDelete"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--edit dialog  -->
    <v-dialog v-model="editdialog" max-width="700px">
      <v-card rounded="lg">
        <v-card-title>
          <span class="editbox">EDIT BLESSING & MIRACLES</span>
          <v-spacer></v-spacer>
        </v-card-title>

        <v-card-text>
          <v-form>
            <v-layout wrap justify-center px-2>
              <v-flex xs12 pr-2>
                <span class="label"> Blessings & Miracles </span>
                <v-text-field
                  class="pt-2 text-des"
                  style="font-size: 14px"
                  v-model="editingitem.name"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-flex>

              <v-flex xs12 pr-2 pt-3>
                <span class="label"> Upload Image For App Home Screen : </span>
                <ImageComp
                  class="pt-2 text-des"
                  :singleimg="editingitem.imageHome"
                  @stepper="winStepper"
                  :componentType="'img1'"
                />
              </v-flex>

              <v-flex xs12 pr-2 pt-3>
                <span class="label"> Upload Image : </span>
                <ImageComp1
                  class="pt-2 text-des"
                  :singleImage="editingitem.photo"
                  @stepper="winStepper"
                  :componentType="'courseImage'"
                />
              </v-flex>


            
              <v-flex xs12 pr-2 pt-3>
                <span class="label"> Description </span>
                <vue-editor
                  class="pt-2 text-des"
                  v-model="editingitem.description"
                ></vue-editor>
              </v-flex>
            </v-layout>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="py-4 justify-end headline grey lighten-2">
          <v-btn outlined color="grey" text @click="editdialog = false">
            <span style="color: black">Cancel</span>
          </v-btn>
          <v-btn color="primary" @click="validateInputEdit(editingitem._id)"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import ImageComp from "@/components/Common/blessingiImageCrop";
import ImageComp1 from "@/components/Common/blessingcoverimg";
import { VueEditor } from "vue2-editor";
export default {
  components: {
    // VueCropper,
    ImageComp,
    ImageComp1,
    VueEditor,
  },
  data() {
    return {
      showSnackBar: false,
      timeout: 5000,
      msg: "",
      appLoading: false,
      dialog: false,
      deletedialog: false,
      miraclelist: [],
      name: null,
      description: null,
      image: null,
      courseImage: "",
      img1:"",
      editdialog: false,
      editingitem: {},
      page: 1,
        currentPage: 1,
        pages: 0,
        limit: 10,
      ServerError: false,

      headers: [
        { text: "Title", value: "name", width: "400px" },
        { text: "Description", value: "description", width: "110px" },
        { text: "Actions", value: "_id", width: "5px" },
      ],
    };
  },
  watch:{

currentPage() {
  this.getData();
},
  },
  mounted() {
    this.getData(); // Fetch data when component mounts
  },
  methods: {
    redirectToViewPage(item) {
      this.$router.push("/blessingSingleView?id=" + item._id);
    },
    stripHtmlTags(html) {
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = html;
      return tempDiv.textContent || tempDiv.innerText || "";
    },
    truncateDescription(description) {
      const cleanedDescription = this.stripHtmlTags(description);
      return cleanedDescription.length > 20
        ? `${cleanedDescription.slice(0, 20)}...`
        : cleanedDescription;
    },

    winStepper(window_data) {
      if (window_data.type == "courseImage") {
        this.courseImage = window_data.selectedFiles;
      }
      if (window_data.type == "img1") {
        this.img1 = window_data.selectedFiles;
      }
    },

    uploadImage(id) {
      let formData = new FormData();
      formData.append("id", id);
      formData.append("photo", this.courseImage);

      axios({
        method: "POST",
        url: "/miracles/images/add",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            
            this.dialog = false;
            formData = new FormData();
            this.courseImage = "";
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    uploadImage2(id) {
      let formData = new FormData();
      formData.append("id", id);
      formData.append("photo", this.img1);

      axios({
        method: "POST",
        url: "/miracles/images/add/appHome",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            
            this.dialog = false;
            formData = new FormData();
            this.img1 = "";
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    validateInput() {
      if (!this.name) {
        this.msg = "Please provide title of the miracle";
        this.showSnackBar = true;
        return;
      } else if (!this.description) {
        this.msg = "Please provide short description";
        this.showSnackBar = true;
        return;
      } else if (!this.courseImage) {
        this.msg = "Please provide cover image";
        this.showSnackBar = true;
        return;
      } 
      else if (!this.img1) {
        this.msg = "Please provide image";
        this.showSnackBar = true;
        return;
      } 
      else {
        this.addMiracle();
      }
    },

    addMiracle() {
      this.appLoading = true;
      var user = {};
      user["name"] = this.name;
      user["description"] = this.description;

      axios({
        url: "/add/new/miracle",
        method: "POST",
        data: user,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            var id = response.data._id;
            if (this.courseImage) {
              this.uploadImage(id);
            }
            if (this.img1) {
              this.uploadImage2(id);
            }
            this.dialog = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.getData();
       
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getData() {
      this.appLoading = true;
      axios({
        url: "/get/all/miracles",

        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
        limit: this.limit,
        page: this.currentPage,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.miraclelist = response.data.data;
          this.pages = Math.ceil(response.data.totalLength / this.limit);
          
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    validateInputEdit() {
      if (!this.editingitem.name) {
        this.msg = "Please provide title of the miracle";
        this.showSnackBar = true;
        return;
      } else if (!this.editingitem.description) {
        this.msg = "Please provide short description";
        this.showSnackBar = true;
        return;
      } else if (!this.editingitem.photo) {
        this.msg = "Please provide coverimage";
        this.showSnackBar = true;
        return;
      }
      else if (!this.editingitem.imageHome) {
        this.msg = "Please provide home screen image";
        this.showSnackBar = true;
        return;
      } else {
        this.edit();
      }
    },

    editSlider(item) {
      this.editingitem = { ...item }; // Ensure to create a copy of the item
      this.editdialog = true;
    },
    edit() {
      this.appLoading = true;
      const user = {
        id: this.editingitem._id,
        name: this.editingitem.name,
        description: this.editingitem.description,
      };

      axios
        .post("/edit/miracle", user, {
          headers: { token: localStorage.getItem("token") },
        })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.editdialog = false;
            if (this.courseImage) {
              this.uploadImage(this.editingitem._id);
            } 
            if (this.img1) {
              this.uploadImage2(this.editingitem._id);
            } else {
              this.getData();
              this.editdialog = false;
              this.msg = response.data.msg;
              this.showSnackBar = true;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    openDeleteDialog(item) {
      this.itemToDelete = item;
      this.deletedialog = true;
    },
    confirmDelete() {
      if (this.itemToDelete) {
        this.deleteItem(this.itemToDelete);
      }
      this.deletedialog = false;
    },
    deleteItem(r) {
      var data = {};
      data["id"] = r._id;
      axios({
        url: "/delete/miracle",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.church-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}
</style>